import { Controller } from "stimulus"
import { setLayout } from "./comparator_controller.js"
// import "../lib/select2"

export default class extends Controller {
  openSelect(event) {
    Alpine.store("productGranits").isHide = true
    let idx1 = event.currentTarget.getAttribute("data-n-select")
    closeAllSelect(idx1)
    let ulContlis = document.querySelectorAll("[data-index-select='" + idx1 + "'] .select_int > li")
    let hg = 0
    let slectOpen = document.querySelectorAll("[data-index-select='" + idx1 + "']")[0].getAttribute("data-selec-open")
    // let slectElementOpen = document.querySelectorAll("[data-index-select='" + idx1 + "'] select")[0]

    for (const ulContli of ulContlis) {
      hg += ulContli.offsetHeight
    }
    if (slectOpen === "false") {
      document.querySelectorAll("[data-index-select='" + idx1 + "']")[0].setAttribute("data-selec-open", "true")
      document.querySelectorAll("[data-index-select='" + idx1 + "'] > .list_select_mate > ul")[0].style.height = hg + "px"
      //  document.querySelectorAll("[data-index-select='" + idx1 + "'] > .icon_select_mate")[0].style.transform = 'rotate(180deg)'
    } else {
      document.querySelectorAll("[data-index-select='" + idx1 + "']")[0].setAttribute("data-selec-open", "false")
      document.querySelectorAll("[data-index-select='" + idx1 + "'] > .icon_select_mate")[0].style.transform = "rotate(0deg)"
      document.querySelectorAll("[data-index-select='" + idx1 + "'] > .list_select_mate > ul")[0].style.height = "0px"
    }
  }// end function openSelect
}
function change(type, value, text = false) {
  const elementACC = document.getElementById("accessories-catalog")
  const elementPGs = !!document.getElementById("product-granits")
  // const elementGranite = document.getElementById("catalogs-granites")
  switch (type) {
    case "granite":
      if (elementPGs) {
        Alpine.store("productGranits").onFilterByGranit(value, true)
      } else {
        Alpine.store("gpg").onClickGranit(value, elementACC)
      }
      break
    case "category": Alpine.store("gpg").onClickCategory(value, elementACC)
      break
    case "origin": Alpine.store("granit").onSearch({ type: "origin", value: value })
      break
    case "layout": Alpine.store("catalogs").onClickLayout(value, text) // eslint-disable-line no-undef
      break
    case "stock_category": Alpine.store("stock").onSearch({ type: "category", value: value })
      break
    case "stock_layouts": Alpine.store("stock").onSearch({ type: "layout", value: value, text: text })
      break
    case "stock_productType": Alpine.store("stock").onSearch({ type: "productType", value: value })
      break
    case "stock_granite": Alpine.store("stock").onSearch({ type: "granite", value: value })
      break
    case "stock_availability": Alpine.store("stock").onSearch({ type: "available", value: value })
      break
    case "comparatorlayout": setLayout(value)
      break
  }
}
window.onload = function () {
  createSelect()
  document.addEventListener("mouseup", function (e) {
    document.getElementsByClassName("select_mate").forEach(element => {
      if (!element.contains(e.target) && element.getAttribute("data-selec-open") === "true") {
        closeAllSelect(0)
      }
    })
  })
}

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1)
}
function _selectOption(index, selectedIndex) {
  let select_ = document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > select")[0]
  let elements = document.querySelectorAll("[data-index-select='" + selectedIndex + "'] .select_int > li")
  document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > .selected_option")[0].innerHTML = elements[index].innerHTML
  let selectOptions = document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > select > option")
  for (const element of elements) {
    if (element.className === "active") {
      element.className = ""
    }
    elements[index].className = "active"
  }
  selectOptions[index].selected = true
  select_.selectedIndex = index
  //  console.log(select_.id)
  let text = selectOptions[index].textContent ? selectOptions[index].textContent : false
  change(select_.id, selectOptions[index].value, text)
  //  select_.onchange()
  closeSelect(selectedIndex)
}
export function createSelectByValue(id, list, selectedItem) {
  let li = []
  const ulCont = document.querySelectorAll(`#${id}`)
  let listItems = document.querySelectorAll(`#${id} li`)
  const choosedSelect = document.getElementById(`list_${id}`)
  const e = choosedSelect.getAttribute("data-index-select")

  for (let i = 0; i < listItems.length; i++) {
    listItems[i].remove()
  }
  for (let i = 0; i < list.length; i++) {
    li[i] = document.createElement("li")
    if (list[i].selected === true || Alpine.store("catalogs").layout === list[i].id || selectedItem === list[i].id) {
      li[i].className = "active"
      if (document.querySelector("[data-index-select='" + e + "']  > .selected_option ")) {
        document.querySelector("[data-index-select='" + e + "']  > .selected_option ").innerHTML = list[i].name
      }
    }
    li[i].setAttribute("data-index", i)
    li[i].setAttribute("data-selec-index", e)
    li[i].setAttribute("value", list[i].id)
    // funcion click select
    li[i].addEventListener("click", function () { _selectOption(this.getAttribute("data-index"), this.getAttribute("data-selec-index")) })

    li[i].innerHTML = list[i].name
    ulCont[0].append(li[i])
  }
}
export function createSelect() {
  let divContSelect = document.querySelectorAll("[data-mate-select='active']")
  let select_ = ""
  for (let e = 0; e < divContSelect.length; e++) {
    divContSelect[e].setAttribute("data-index-select", e)
    divContSelect[e].setAttribute("data-selec-open", "false")
    let ulCont = document.querySelectorAll("[data-index-select='" + e + "'] > .list_select_mate > ul")
    select_ = document.querySelectorAll("[data-index-select='" + e + "'] >select")[0]
    if (isMobileDevice()) {
      select_.addEventListener("change", function () {
        _selectOption(select_.selectedIndex, e)
      })
    }
    let selectOptions = select_.options
    let li = []
    document.querySelectorAll("[data-index-select='" + e + "']  > .selected_option ")[0].setAttribute("data-n-select", e)
    document.querySelectorAll("[data-index-select='" + e + "']  > .icon_select_mate ")[0].setAttribute("data-n-select", e)
    if (!divContSelect[e].getAttribute("isloaded") && divContSelect[e].getAttribute("name") !== "layout") {
      divContSelect[e].setAttribute("isloaded", true)
      for (let i = 0; i < selectOptions.length; i++) {
        li[i] = document.createElement("li")
        if (selectOptions[i].selected === true || select_.value === selectOptions[i].innerHTML) {
          li[i].className = "active"
          document.querySelector("[data-index-select='" + e + "']  > .selected_option ").innerHTML = selectOptions[i].innerHTML
        }
        li[i].setAttribute("data-index", i)
        li[i].setAttribute("data-selec-index", e)
        // funcion click select
        li[i].addEventListener("click", function () { _selectOption(this.getAttribute("data-index"), this.getAttribute("data-selec-index")) })

        li[i].innerHTML = selectOptions[i].innerHTML
        ulCont[0].appendChild(li[i])
      } // end for selectOptions
    }
  } // end for divsContSelect
} // end Function

function closeSelect(index) {
//   let select_ = document.querySelectorAll("[data-index-select='" + index + "'] > select")[0]
  document.querySelectorAll("[data-index-select='" + index + "'] > .list_select_mate > ul")[0].style.height = "0px"
  document.querySelector("[data-index-select='" + index + "'] > .icon_select_mate").style.transform = "rotate(0deg)"
  document.querySelectorAll("[data-index-select='" + index + "']")[0].setAttribute("data-selec-open", "false")
}

export function closeAllSelect(selectedIndex) {
  document.getElementsByClassName("select_mate").forEach(element => {
    const index = element.getAttribute("data-index-select")
    if (index !== selectedIndex) {
      closeSelect(index)
    }
  })
}
