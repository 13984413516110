import { Application } from "stimulus"
// import { definitionsFromContext } from "stimulus/webpack-helpers"

import AdminController from "./admin_controller"
import AttachmentsController from "./attachments_controller"
import AutosubmitController from "./autosubmit_controller"
import CarouselController from "./carousel_controller"
import CheckboxCollapseController from "./checkbox_collapse_controller"
import CheckboxesController from "./checkboxes_controller"
import ClassListController from "./class_list_controller"
import CollapseController from "./collapse_controller"
import Config3dController from "./config3d_controller"
import DocumentsController from "./documents_controller"
import DropdownController from "./dropdown_controller"
import EmailFieldController from "./email_field_controller"
import FileInputController from "./file_input_controller"
import FiltersController from "./filters_controller"
import FlashMessageController from "./flash_message_controller"
import FormController from "./form_controller"
import HeaderController from "./header_controller"
import LazyLoadController from "./lazy_load_controller"
import LightboxController from "./lightbox_controller"
import LinkDocumentsController from "./link_documents_controller"
import ListController from "./list_controller"
import ListItemController from "./list_item_controller"
import MaxlengthController from "./maxlength_controller"
import ModalController from "./modal_controller"
import MonumentRequestMultiplicatorController from "./monument_request_multiplicator_controller"
import OrganizationsController from "./organizations_controller"
import RadioController from "./radio_controller"
import RecipientsController from "./recipients_controller"
import ResponseContentController from "./response_content_controller"
import ResponseController from "./response_controller"
import ScrollController from "./scroll_controller"
import DivscrollerController from "./divscroller_controller"
import ScrollerController from "./scroller_controller"
import ScrolltopController from "./scrolltop_controller"
import SearchInputController from "./search_input_controller"
import SidebarController from "./sidebar_controller"
import SortableController from "./sortable_controller"
import TooltipController from "./tooltip_controller"
import Viewer3dController from "./viewer3d_controller"
import ZoomOverController from "./zoom_over_controller"
import GranitsController from "./granits_controller"
import QuoteController from "./quote_controller"
import ProfileController from "./profile_controller"
import MsgPopinController from "./msgpopin_controller"
import ExplorerController from "./explorer_controller"
import Newscontroller from "./news_controller"
import OrganizationController from "./organization_controller"
import MemberController from "./member_controller"
import PrimonumentsController from "./primonuments_controller"
import MonushopController from "./monushop_controller"
import PopupController from "./popup_controller"
import LangueController from "./langue_controller"
import ComparatorController from "./comparator_controller"
import LastConfigController from "./last_config_controller"

import CatalogsController from "./catalogs_controller"
import FilterCatalogsController from "./filter_catalog_controller"

import OnboardingController from "./onboarding_controller"
import IntroController from "./intro_controller"
import BoxController from "./box_controller"
import CopyToClipboardController from "./copy_to_clipboard_controller"
import SentMailController from "./sent_mail_controller"
import GtmController from "./gtm_controller"
import SelectNewstyleController from "./select_newstyle_controller"
import KeywordsController from "./keywords_controller"
import DocumentModalController from "./document_modal_controller"
import ShareFormController from "./share_form_controller"
import MessagePopupController from "./message_popup_controller"
import introConfigController from "./intro_config_controller"
import BoxLinkController from "./box_link_controller"

const application = Application.start()
// const context = require.context("controllers", true, /_controller.js$/)
// const definitions = definitionsFromContext(context)
// application.load(definitions)
application.register("admin", AdminController) // FIXME: use generic controller (zoom or lightbox)
application.register("attachments", AttachmentsController)
application.register("autosubmit", AutosubmitController)
application.register("carousel", CarouselController)
application.register("checkbox-collapse", CheckboxCollapseController)
application.register("checkboxes", CheckboxesController)
application.register("class-list", ClassListController)
application.register("collapse", CollapseController)
application.register("config3d", Config3dController)
application.register("documents", DocumentsController)
application.register("dropdown", DropdownController)
application.register("email-field", EmailFieldController)
application.register("file-input", FileInputController)
application.register("filters", FiltersController)
application.register("flash-message", FlashMessageController)
application.register("form", FormController)
application.register("header", HeaderController)
application.register("lazy-load", LazyLoadController)
application.register("lightbox", LightboxController)
application.register("link-documents", LinkDocumentsController)
application.register("list", ListController)
application.register("list-item", ListItemController)
application.register("maxlength", MaxlengthController)
application.register("modal", ModalController)
application.register("monument-request-multiplicator", MonumentRequestMultiplicatorController) // FIXME: use generic controller
application.register("organizations", OrganizationsController)
application.register("radio", RadioController)
application.register("recipients", RecipientsController)
application.register("response-content", ResponseContentController)
application.register("response", ResponseController)
application.register("scroll", ScrollController)
application.register("scroller", ScrollerController)
application.register("divscroller", DivscrollerController)
application.register("scrolltop", ScrolltopController)
application.register("search-input", SearchInputController)
application.register("sidebar", SidebarController)
application.register("sortable", SortableController)
application.register("tooltip", TooltipController)
application.register("viewer3d", Viewer3dController)
application.register("zoom-over", ZoomOverController)
application.register("granits", GranitsController)
application.register("quote", QuoteController)
application.register("profile", ProfileController)
application.register("msg-popin", MsgPopinController)
application.register("explorer", ExplorerController)
application.register("news", Newscontroller)
application.register("organization", OrganizationController)
application.register("member", MemberController)
application.register("monushop", MonushopController)
application.register("pri", PrimonumentsController)
application.register("popup", PopupController)
application.register("langue", LangueController)
application.register("comparator", ComparatorController)
application.register("last-config", LastConfigController)

application.register("catalogs", CatalogsController)
application.register("filtercatalogs", FilterCatalogsController)

application.register("onboarding", OnboardingController)
application.register("intro", IntroController)
application.register("box", BoxController)
application.register("copy-to-clipboard", CopyToClipboardController)
application.register("sent-mail", SentMailController)
application.register("gtm", GtmController)
application.register("selectnewstyle", SelectNewstyleController)

application.register("keywords", KeywordsController)
application.register("document-modal", DocumentModalController)
application.register("share-form", ShareFormController)
application.register("message-popup", MessagePopupController)
application.register("intro-config", introConfigController)
application.register("box-link", BoxLinkController)
