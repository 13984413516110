import { Controller } from "stimulus"
import { request } from "../lib/request"

export default class extends Controller {
  toggleArchive(event) {
    event.preventDefault()
    event.stopPropagation()

    const target = event.currentTarget

    request(target.dataset.method, target.form.action).then(response => {
      if (this.element.dataset.removeOnArchive === "true") {
        this.element.remove()
      } else {
        return response.text()
          .then(html => this.element.innerHTML = html)
      }
    })
  }

  openModal(event) {
    document.body
      .modalController
      .open(event.currentTarget.href, { controller: this })
    event.preventDefault()
  }

  // openModalWithLink
  openModalWithLink(event) {
    const link = event.currentTarget.getAttribute("link")
    document.body
      .modalController
      .open(link || event.currentTarget.href, { controller: this })
    event.preventDefault()
  }

  onConflict(response) {
    response.text.then(html => this.element.innerHTML = html)
  }

  postEvent(event) {
    if (event.type !== "ajax:complete") return
    const xhr = event.detail[0]

    // NOTE: should always be true (see ModalController)
    if (xhr.status !== 204 && xhr.status < 300) {
      const fragment = document.createElement("div")
      fragment.innerHTML = xhr.response

      const template = fragment.querySelector("template#list-item")

      if (template) {
        // response has updated contents for both modal (whole response) and the
        // list-item (template element):
        this.element.innerHTML = template.innerHTML
        template.remove()
        document.body.modalController.setContents(fragment.innerHTML)
      } else {
        // response is only the list-item (close the modal):
        this.element.innerHTML = xhr.response
        document.body.modalController.close()
      }
    }
  }
}
