import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.checkAllInit()
  }

  checkListner(event) {
    const element = event.target
    if (element.classList.contains("check")) {
      const checkWAllInput = document.getElementById("check-all")
      if (checkWAllInput) {
        if (this.isChecked(element.checked, "check")) {
          checkWAllInput.checked = element.checked
        } else if (!element.checked) {
          checkWAllInput.checked = false
        }
      }
    }
  }

  checkAll(event, classToCheck = "check") {
    const check = event.target.checked
    let elements = document.getElementsByClassName(classToCheck)
    for (let i = 0; i < elements.length; i++) {
      elements[i].checked = check
    }
  }

  checkAllInit() {
    const checkWAllInput = document.getElementById("check-all")
    if (checkWAllInput && this.isChecked(true, "check")) {
      checkWAllInput.checked = true
    }
  }

  isChecked(check, checkCLass) {
    let elements = document.getElementsByClassName(checkCLass)
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].checked !== check) {
        return false
      }
    }
    return true
  }
}
