import { Controller } from "stimulus"
import { get, post } from "../lib/request"
import template from "../lib/template"
export default class extends Controller {
  static targets = ["content", "view360Template", "printBtn", "downloadBtn", "modal", "closeIcon", "shareBtn", "sellingButton", "container", "iframeBtn", "docLoader", "buyingButton"]

  connect() {
    if (this.data.has("generating")) {
      window.setTimeout(() => {
        this._waitFor(this.data.get("generating"), this.data.get("method"))
      }, 20000)
    } else {
      if (this.hasContainerTarget && (this.hasSellingButtonTarget || this.hasBuyingButtonTarget)) {
        const documentType = this.containerTarget.dataset.documentType
        if (documentType === "quote" && this.checkQuoteImages()) {
          return
        }

        const mntCategory = this.containerTarget.dataset.mntCategory
        if (mntCategory === "ACC") {
          this.clickQuotebutton()
        }
      }
      const target = this.modalTarget.querySelector("a.active")
      if (!target) {
        this.disableDownloadAndPrintButtons()
        return
      }

      this._setButtons(target)

      if (window.location.href.includes("documents")) {
        document.body.style.height = "100vh"
      }

      if (window.location.search.includes("share=true")) {
        this.shareBtnTarget.click()
      }
    }
  }

  disableDownloadAndPrintButtons() {
    this.downloadBtnTarget.classList.add("button-inactive")
    this.downloadBtnTarget.classList.remove("button-secondary")
    this.printBtnTarget.classList.add("button-inactive")
    this.printBtnTarget.classList.remove("button-secondary")
    this.downloadBtnTarget.href = "#"
    this.printBtnTarget.href = "#"
  }

  clickQuotebutton() {
    if (this.hasSellingButtonTarget) {
      setTimeout(() => this.sellingButtonTarget.click(), 100)
    } else if (this.hasBuyingButtonTarget) {
      setTimeout(() => this.buyingButtonTarget.click(), 100)
    }
  }

  checkQuoteImages() {
    const fileTexture = this.containerTarget.dataset.fileTexture
    const fileWireframe = this.containerTarget.dataset.fileWireframe

    if (!fileTexture || !fileWireframe) {
      // click on the buying button
      this.clickQuotebutton()
      return true
    }
    return false
  }

  changeContent(event) {
    event.preventDefault()
    const partialPath = event.currentTarget.getAttribute("data-partial-path")
    const req = fetch(partialPath, { headers: { "x-requested-with": "XMLHttpRequest" } })

    this.contentTarget.innerHTML = ""
    this.docLoaderTarget.style.display = "unset"

    req
      .then(response => response.text())
      .then(html => {
        this.contentTarget.innerHTML = html
        this.docLoaderTarget.style.display = "none"
        this.contentTarget.style.overflowY = "auto"
      })
  }

  openIframe(event) {
    event.preventDefault()
    const element = document.createElement("iframe")
    const iframeUrl = event.currentTarget.getAttribute("data-iframe")

    // add style to iframe
    element.style.width = "100%"
    element.style.height = "100%"

    element.src = iframeUrl

    this.contentTarget.innerHTML = ""
    this.contentTarget.appendChild(element)
  }

  changeImage(event) {
    event.preventDefault()
    this.contentTarget.innerHTML = ""

    const image = document.createElement("img")
    image.setAttribute("src", event.currentTarget.href)
    image.setAttribute("loading", "lazy")
    image.setAttribute("data-target", "lazy-load.image")

    this.contentTarget.appendChild(image)
  }

  openView360(event) {
    event.preventDefault()
    const element = template.clone(this.view360TemplateTarget)

    this.contentTarget.innerHTML = ""
    this.contentTarget.appendChild(element)
  }

  handleChange(event) {
    this.contentTarget.style.overflowY = "unset"

    this._clearNav()

    event.currentTarget.classList.add("active")

    // Add 'active' class to the span with class 'image-label' inside the currentTarget
    const imageLabelSpan = event.currentTarget.querySelector("div > span.image-label")
    if (imageLabelSpan) {
      imageLabelSpan.classList.add("active")
    }

    this._setButtons(event.currentTarget)
  }

  print(event) {
    const w = screen.width * 0.8
    const h = screen.height * 0.75
    const x = screen.width / 2 - w / 2
    const y = screen.height / 2 - h / 2
    window.open(event.currentTarget.href, "Impression", `height=${h},width=${w},left=${x},top=${y}`)

    event.preventDefault()
  }

  showIcon(event) {
    event.preventDefault()
    this.closeIconTarget.style.display = "block"
  }

  hideIcon(event) {
    event.preventDefault()
    this.closeIconTarget.style.display = "none"

    // add active class to #defaultOpen
    this.element.querySelector("#defaultOpen").classList.add("active")
  }

  _setButtons(target) {
    if (target.dataset.download) {
      this.downloadBtnTarget.href = target.dataset.download
      this.printBtnTarget.href = target.dataset.print
    }

    this._resetButtons()
  }

  _resetButtons() {
    const buttons = [this.downloadBtnTarget, this.printBtnTarget, this.shareBtnTarget]

    if (this.hasIframeBtnTarget) {
      buttons.push(this.iframeBtnTarget)
    }

    buttons.forEach(button => {
      button.classList.remove("button-inactive", "active")
      button.classList.add("button-secondary")
    })
  }

  editBtnClass(event) {
    const type = event.currentTarget.dataset.type
    const buttonsToModify = [this.downloadBtnTarget, this.printBtnTarget]

    buttonsToModify.forEach(button => {
      button.classList.remove("button-secondary")
      button.classList.toggle("button-inactive", type === "view360" || type === "share" || type === "iframe")
    })

    this.shareBtnTarget.classList.toggle("active", type === "share")
    if (this.hasIframeBtnTarget) {
      this.iframeBtnTarget.classList.toggle("active", type === "iframe")
    }
  }

  _clearNav() {
    this.element.querySelectorAll(".left-monument a").forEach(element => {
      element.classList.remove("active")
    })

    // Remove 'active' class from all spans with class 'image-label'
    this.element.querySelectorAll("div > span.image-label").forEach(span => {
      span.classList.remove("active")
    })

    this.closeIconTarget.style.display = "none"
  }

  _waitFor(path, method) {
    if (method === "POST") {
      post(path + "?check=" + Date.now())
        .then(response => response.text())
        .then(html => {
          if (this.element.parentElement !== null) {
            this.element.outerHTML = html
          }
        })
    } else {
      get(path + "?check=" + Date.now())
        .then(response => response.text())
        .then(html => {
          if (this.element.parentElement !== null) {
            this.element.outerHTML = html
          }
        })
    }
  }
}
